export const oldbaseUrl = (state) => {
  return state.oldbaseUrl;
};
export const vChargeBaseUrl = (state) => {
  return state.vChargeBaseUrl;
};
export const vCmsBaseUrl = (state) => {
  return state.vCmsBaseUrl;
};
export const vCrmBaseUrl = (state) => {
  return state.vCrmBaseUrl;
};
export const vDrmBaseUrl = (state) => {
  return state.vDrmBaseUrl;
};
export const vSmsBaseUrl = (state) => {
  return state.vSmsBaseUrl;
};
export const listextrapaths = (state) => {
  return state.listextrapaths;
};
export const getMode = (state) => {
  return state.mode;
};
export const appConfig = (state) => {
  return state.appConfig;
};
export const country = (state) => {
  return state.country;
};
export const getToken = (state) => {
  return state.token;
};
export const subscriberid = (state) => {
  return state.subscriberid;
};
export const secret_key = (state) => {
  return state.secretKey;
};
export const getGenreData = (state) => {
  return state.genreData;
};
export const profileid = (state) => {
  return state.profileid;
};
export const kidsMode = (state) => {
  return state.kidsMode;
};
export const profilePicture = (state) => {
  return state.profilePicture;
};
export const availabilityList = (state) => {
  return state.availabilityList;
};
export const subscriptionList = (state) => {
  return state.subscriptionList;
};
export const purchaseList = (state) => {
  return state.purchaseList;
};
export const newSubscription = (state) => {
  return state.newSubscription;
};
export const newPurchase = (state) => {
  return state.newPurchase;
};
export const currentBrowser = (state) => {
  return state.currentBrowser;
};

export const getDisplayLanguage = (state) => {
  return state.displayLanguage;
};

export const getDisplayLanguageCode = (state) => {
  return state.displayLanguageCode;
};

export const getRtl = (state) => {
  return state.rtl;
};

export const getCountry = (state) => {
  return state.country;
};

export const isMovieEnabled = (state) => {
  return state.isMovieEnabled;
};

export const getUnauthorizedErrorOccured = (state) => {
  return state.unauthorizedErrorOccured;
};

export const getFirebaseUserUID = (state) => {
  return state.firebaseUserUID;
};

export const getRegActiveDeeplink = (state) => {
  return state.regActiveDeeplink;
};

export const getGatewayList = (state) => {
  return state.gatewayList;
};

export const getIsManageCards = (state) => {
  return state.isManageCards;
};

export const getContinueWatchData =  (state) =>{
  return state.ContinueWatchData;
}
export const getAudioAndSubtitleLangState = (state) => {
  return state.audioAndSubtitleLangState;
};

export const getAllLanguageFullForm = (state) => {
  return state.allLanguageFullForm;
};

export const getEpisodePaginationInProgress = (state) => {
  return state.episodePaginationInProgress;
};

export const getSubscriberProfileDetails = (state) => {
  return state.subscriberProfileDetails;
};

export const getPlanDeeplinkPath = (state) => {
  return state.planDeeplinkPath;
};

export const getCountryListByCode = (state) => {
  return state.planDeeplinkPath;
};
export const getCountryCodesList = (state) => {
  return state.countryCodesList;
};
export const getPairingFlag = (state) => {
	return state.pairingFlag;
};

// export const getSearchdata = (state) => {
//   return state.searchList;
// };
export const getSearchdata = (state) => {
  return {dataset: state.searchList, totalcount: state.searchTotalCount};
};
export const getSubscriptionInformation = (state) => {
  return state.subscriptionInformation;
};

export const getContentData = (state) => {
  return state.contentData;
}
